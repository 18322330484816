import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'Installation',
    path: '/installation',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'LocalHost',
        path: '/installation/#localhost',
        icon: <IoIcons.IoIosGlobe />
      },
      {
        title: 'cPanel',
        path: '/installation/#cpanel',
        icon: <IoIcons.IoIosHome />
      }
    ]
  },
  {
    title: 'Frontend',
    path: '/frontend',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Home',
        path: '/frontend/#ho1me',
        icon: <IoIcons.IoMdBaseball />,
        cName: 'sub-nav'
      },
      {
        title: 'Login',
        path: '/frontend/#lo1gin',
        icon: <IoIcons.IoLogoFlickr />,
        cName: 'sub-nav'
      },
      {
        title: 'Alumni',
        path: '/frontend/#al1umni',
        icon: <IoIcons.IoMdCellular />,
        cName: 'sub-nav'
      },
      {
        title: 'Chat Room',
        path: '/frontend/#ch1atroom',
        icon: <IoIcons.IoMdHeartEmpty />,
        cName: 'sub-nav'
      },
      {
        title: 'Donation',
        path: '/frontend/#do1nation',
        icon: <IoIcons.IoIosShuffle />,
        cName: 'sub-nav'
      },
      {
        title: 'Events',
        path: '/frontend/#ev1ents',
        icon: <IoIcons.IoMdCash />,
        cName: 'sub-nav'
      },
      {
        title: 'Stories',
        path: '/frontend/#st1ories',
        icon: <IoIcons.IoMdAperture />,
        cName: 'sub-nav'
      },
      {
        title: 'Gallery',
        path: '/frontend/#ga1llery',
        icon: <IoIcons.IoMdVolumeHigh />,
        cName: 'sub-nav'
      },
      {
        title: 'Volunteers',
        path: '/frontend/#vo1lunteers',
        icon: <IoIcons.IoIosRewind />,
        cName: 'sub-nav'
      },
      {
        title: 'Notice Board',
        path: '/frontend/#no1ticeboard',
        icon: <IoIcons.IoIosVolumeHigh />,
        cName: 'sub-nav'
      },
      {
        title: 'Contact Us',
        path: '/frontend/#co1ntactus',
        icon: <IoIcons.IoMdStats />,
        cName: 'sub-nav'
      },
    ]
  },
  {
    title: 'Backend',
    path: '/backend',
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
        {
            title: 'Dashboard',
            path: '/backend/#dashboard',
            icon: <IoIcons.IoIosBus />,
            cName: 'sub-nav'
          },
          {
            title: 'Alumnus',
            path: '/backend/#alumnus',
            icon: <IoIcons.IoIosCheckbox />,
            cName: 'sub-nav'
          },
          {
            title: 'Slider',
            path: '/backend/#slider',
            icon: <IoIcons.IoIosPhoneLandscape />,
            cName: 'sub-nav'
          },
          {
            title: 'About Us',
            path: '/backend/#aboutus',
            icon: <IoIcons.IoIosReorder />,
            cName: 'sub-nav'
          },
          {
            title: 'Event',
            path: '/backend/#event',
            icon: <IoIcons.IoLogoApple />,
            cName: 'sub-nav'
          },
          {
            title: 'Story',
            path: '/backend/#story',
            icon: <IoIcons.IoLogoJavascript />,
            cName: 'sub-nav'
          },
          {
            title: 'Comment',
            path: '/backend/#comment',
            icon: <IoIcons.IoMdArrowDroprightCircle />,
            cName: 'sub-nav'
          },
          {
            title: 'Gallery',
            path: '/backend/#gallery',
            icon: <IoIcons.IoIosBowtie />,
            cName: 'sub-nav'
          },
          {
            title: 'Volunteer',
            path: '/backend/#volunteers',
            icon: <IoIcons.IoIosColorWand />,
            cName: 'sub-nav'
          },
          {
            title: 'Notice Board',
            path: '/backend/#noticeboard',
            icon: <IoIcons.IoIosPricetags />,
            cName: 'sub-nav'
          },
          {
            title: 'Donation',
            path: '/backend/#donation',
            icon: <IoIcons.IoIosHeartDislike />,
            cName: 'sub-nav'
          },
          {
            title: 'Contact Us',
            path: '/backend/#contactus',
            icon: <IoIcons.IoIosSync />,
            cName: 'sub-nav'
          },
          {
            title: 'Message',
            path: '/backend/#message',
            icon: <IoIcons.IoIosVolumeMute />,
            cName: 'sub-nav'
          },
          {
            title: 'Settings',
            path: '/backend/#settings',
            icon: <IoIcons.IoMdArrowDropdownCircle />,
            cName: 'sub-nav'
          },
    ]
  },
  {
    title: 'About',
    path: '/about',
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
        {
            title: 'Technologies Used',
            path: '/about/#techs',
            icon: <IoIcons.IoMdBackspace />,
            cName: 'sub-nav'
          },
          {
            title: 'Support',
            path: '/about/#support',
            icon: <IoIcons.IoMdListBox />,
            cName: 'sub-nav'
          },
          {
            title: 'Login Credentials',
            path: '/about/#logincreds',
            icon: <IoIcons.IoMdMegaphone />,
            cName: 'sub-nav'
          },
    ]
  }
];
